<div class="about-title about-title-bg">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="about-title-text">
                    <h2>About Us</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-rounded-double-right"></i> About Us</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="about-style-two about-style-three pt-100 pb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 p-0">
                <div class="about-img">
                    <img src="assets/img/about-two.png" alt="about image">
                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-text">   
                    <div class="section-head">
                        <h2>We have been doing projects since 2008.</h2>
                        <p>It is a long fact that a reader will be distracted by the readable content.</p>
                    </div>

                    <ul>
                        <li>
                            <i class="icofont-check-circled"></i>
                            100% Secured
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i>
                            Fast & Clean Service     
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i>
                            Well Disciplined
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i>
                            24/7 Services
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i>
                            Budget Oriented
                        </li>
                        <li>
                            <i class="icofont-check-circled"></i>
                            Online Payment
                        </li>
                    </ul>

                    <div class="theme-button">
                        <a routerLink="/" class="default-btn">Learn More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="counter-section pt-100 pb-70 mb-0">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5">
                <div class="offer-text">
                    <h2>Get <span>15% Discount</span> on your first Service</h2>
                    <div class="theme-button">
                        <a routerLink="/" class="default-btn">Our Services</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-7">
                <div class="counter-area">
                    <div class="row">
                        <div class="col-lg-5 col-md-3 col-6 offset-lg-1">
                            <div class="counter-text">
                                <h2><span class="counter">1</span>K</h2>
                                <p>Job Done</p>
                            </div>
                        </div>

                        <div class="col-lg-5 col-md-3 col-6">
                            <div class="counter-text">
                                <h2><span class="counter">989</span></h2>
                                <p>Happy Clients</p>
                            </div>
                        </div>

                        <div class="col-lg-5 col-md-3 col-6 offset-lg-1">
                            <div class="counter-text">
                                <h2><span class="counter">45</span></h2>
                                <p>Experts</p>
                            </div>
                        </div>

                        <div class="col-lg-5 col-md-3 col-6">
                            <div class="counter-text">
                                <h2><span class="counter">12</span></h2>
                                <p>Years of Experience</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="counter-shape">
            <img src="assets/img/counter/1.png" alt="shape">
            <img src="assets/img/counter/2.png" alt="shape">
            <img src="assets/img/counter/3.png" alt="shape">
            <img src="assets/img/counter/4.png" alt="shape">
            <img src="assets/img/counter/5.png" alt="shape">
            <img src="assets/img/counter/6.png" alt="shape">
            <img src="assets/img/counter/7.png" alt="shape">
            <img src="assets/img/counter/1.png" alt="shape">
            <img src="assets/img/counter/8.png" alt="shape">
            <img src="assets/img/counter/4.png" alt="shape">
            <img src="assets/img/counter/shape-1.png" alt="bubble shape">
            <img src="assets/img/counter/shape-1.png" alt="bubble shape">
        </div>
    </div>
</div>

<div class="process-section process-style-two pt-100 pb-70">
    <div class="container">
        <div class="section-head blue-title text-center">
            <h2>How Do We <span>Work</span></h2>
            <p>Lorem Ipsum is simply dummied text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.</p>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="process-card">
                    <i class="icofont-touch"></i>
                    <h3>Choose Services</h3>
                    <p>Lorem Ipsum is simply dummied text of the printing and typesetting industry.</p>
                    <img src="assets/img/process-pattern-2.png" alt="arrow">
                </div>
            </div> 
            
            <div class="col-md-4">
                <div class="process-card">
                    <i class="icofont-world"></i>
                    <h3>Booking Online</h3>
                    <p>Lorem Ipsum is simply dummied text of the printing and typesetting industry.</p>
                    <img src="assets/img/process-pattern-2.png" alt="arrow">
                </div>
            </div> 

            <div class="col-md-4">
                <div class="process-card">
                    <i class="icofont-recycle-alt"></i>
                    <h3>Enjoy Cleaning</h3>
                    <p>Lorem Ipsum is simply dummied text of the printing and typesetting industry.</p>
                </div>
            </div> 
        </div>
    </div>
</div>

<section class="team-section team-style-two team-style-three pb-70">
    <div class="container">
        <div class="section-head text-center">
            <h2>Our <span>Team Members</span></h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/1.png" alt="team member">

                        <div class="team-social">
                            <a href="#" target="_blank"><i class="icofont-facebook"></i></a>
                            <a href="#" target="_blank"><i class="icofont-twitter"></i></a>
                            <a href="#" target="_blank"><i class="icofont-linkedin"></i></a>
                            <a href="#" target="_blank"><i class="icofont-instagram"></i></a>
                        </div>
                    </div>

                    <div class="team-text">
                        <h4>John Smith</h4>
                        <p>Head of Cleaner</p>
                    </div>
                </div>
            </div> 
            
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/2.png" alt="team member">

                        <div class="team-social">
                            <a href="#" target="_blank"><i class="icofont-facebook"></i></a>
                            <a href="#" target="_blank"><i class="icofont-twitter"></i></a>
                            <a href="#" target="_blank"><i class="icofont-linkedin"></i></a>
                            <a href="#" target="_blank"><i class="icofont-instagram"></i></a>
                        </div>
                    </div>

                    <div class="team-text">
                        <h4>Marry</h4>
                        <p>Cleaner</p>
                    </div>
                </div>
            </div> 

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/3.png" alt="team member">

                        <div class="team-social">
                            <a href="#" target="_blank"><i class="icofont-facebook"></i></a>
                            <a href="#" target="_blank"><i class="icofont-twitter"></i></a>
                            <a href="#" target="_blank"><i class="icofont-linkedin"></i></a>
                            <a href="#" target="_blank"><i class="icofont-instagram"></i></a>
                        </div>
                    </div>

                    <div class="team-text">
                        <h4>John Smith</h4>
                        <p>Office Cleaner</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/4.png" alt="team member">

                        <div class="team-social">
                            <a href="#" target="_blank"><i class="icofont-facebook"></i></a>
                            <a href="#" target="_blank"><i class="icofont-twitter"></i></a>
                            <a href="#" target="_blank"><i class="icofont-linkedin"></i></a>
                            <a href="#" target="_blank"><i class="icofont-instagram"></i></a>
                        </div>
                    </div>

                    <div class="team-text">
                        <h4>Eve</h4>
                        <p>Assistant</p>
                    </div>
                </div>
            </div> 
        </div>
    </div>
</section>