<div class="header-section">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8 col-md-12">
                <div class="header-widget">
                    <ul>
                        <li><i class="icofont-clock-time"></i> Sun - Thu : 10:00AM - 06:00PM</li>
                        <li><i class="icofont-location-pin"></i> 28/A Street, New York City</li>
                        <li><i class="icofont-phone"></i> <a href="tel:+880123456789">+88 0123 456 789</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-md-4">
                <div class="header-social text-end">
                    <ul>
                        <li><a target="_blank" href="#"><i class="icofont-facebook"></i></a></li>
                        <li><a target="_blank" href="#"><i class="icofont-twitter"></i></a></li>
                        <li><a target="_blank" href="#"><i class="icofont-linkedin"></i></a></li>
                        <li><a target="_blank" href="#"><i class="icofont-instagram"></i></a></li>
                    </ul>
                </div>
            </div>              
        </div>
    </div>
</div>

<div class="navbar-area">
    <div class="mobile-nav">
        <a routerLink="/" class="logo">
            <img src="assets/img/logo.png" alt="logo">
        </a>
    </div>

    <div class="main-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" href="index.html">
                    <img src="assets/img/logo.png" alt="logo">
                </a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav ms-auto">
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Home <i class="icofont-rounded-right"></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Page 1</a></li>

                                <li class="nav-item"><a routerLink="/home-two" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Page 2</a></li>

                                <li class="nav-item"><a routerLink="/home-three" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Page 3</a></li>
                            </ul>
                        </li>

                        <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About</a></li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Services <i class="icofont-rounded-right"></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/service" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Service one</a></li>

                                <li class="nav-item"><a routerLink="/service-two" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Services two</a></li>

                                <li class="nav-item"><a routerLink="/service-three" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Service three</a></li>

                                <li class="nav-item"><a routerLink="/service-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Service details</a></li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Blog <i class="icofont-rounded-right"></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/blog" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog</a></li>

                                <li class="nav-item"><a routerLink="/blog-right-sidebar" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Right sidebar</a></li>

                                <li class="nav-item"><a routerLink="/blog-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog details</a></li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Pages <i class="icofont-rounded-right"></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/pricing" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Pricing</a></li>

                                <li class="nav-item"><a routerLink="/recent-project" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Recent Project</a></li>

                                <li class="nav-item"><a routerLink="/error" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">404 Page</a></li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Team <i class="icofont-rounded-right"></i></a>
                            
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/team" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Team</a></li>

                                <li class="nav-item"><a routerLink="/team-two" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Team two</a></li>
                            </ul>
                        </li>

                        <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                    </ul>

                    <div class="navbar-button">
                        <a routerLink="/contact">Get Quote</a>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>