<div class="home-style-three home-bg-three">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-md-7 offset-lg-1">
                        <div class="home-text-three">
                            <h1>We Are <span>Top Cleaner</span> in Your Area.</h1>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ut labore et dolore magna aliqua.</p>

                            <div class="theme-button">
                                <a routerLink="/testimonial" class="default-btn active-btn">Get A Quote</a>
                                <a routerLink="/service" class="default-btn">Services</a>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-lg-4 col-md-5 p-0">
                        <div class="home-image">
                            <img src="assets/img/home/8.png" alt="home image">
                        </div>
                    </div>
                </div>

                <div class="home-three-shapes">
                    <img src="assets/img/process-pattern-3.png" alt="shape">
                    <img src="assets/img/process-pattern-4.png" alt="shape">
                    <img src="assets/img/process-pattern-5.png" alt="shape">
                    <img src="assets/img/process-pattern-6.png" alt="shape">
                    <img src="assets/img/process-pattern-7.png" alt="shape">
                    <img src="assets/img/process-pattern-6.png" alt="shape">
                </div>
            </div>
        </div>
    </div>
</div>

<section class="about-style-two pt-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about.png" alt="about image">

                    <div class="counter-section">
                        <div class="counter-area">
                            <div class="row">
                                <div class="col-lg-4 col-md-6 col-6 offset-lg-2 col-xl-5 offset-xl-1">
                                    <div class="counter-text">
                                        <h2><span class="counter">1</span>K</h2>
                                        <p>Job Done</p>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6 col-6 col-xl-5">
                                    <div class="counter-text">
                                        <h2><span class="counter">989</span></h2>
                                        <p>Happy Clients</p>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6 col-6 offset-lg-2 col-xl-5 offset-xl-1">
                                    <div class="counter-text">
                                        <h2><span class="counter">45</span></h2>
                                        <p>Experts</p>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6 col-6 col-xl-5">
                                    <div class="counter-text">
                                        <h2><span class="counter">12</span></h2>
                                        <p>Years of Experience</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 pl-35">
                <div class="section-head">
                    <span>About Us</span>
                    <h2>We have been doing projects since 2008.</h2>
                    <p>It is a long fact that a reader will be distracted by the readable content</p>

                </div>
                <div class="about-text">
                    <p>Of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal within the distribution of letters.</p>
                    
                    <ul>
                        <li><i class="icofont-check-circled"></i> 100% Secured</li>
                        <li><i class="icofont-check-circled"></i> 24/7 Services</li>
                        <li><i class="icofont-check-circled"></i> Well Disciplined</li>
                        <li><i class="icofont-check-circled"></i> Online Payment</li>
                    </ul>

                    <div class="theme-button">
                        <a routerLink="/pricing" class="default-btn active-btn">Booking Now</a>
                        <a routerLink="/about" class="default-btn">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="service-style-three pt-100 pb-70">
    <div class="container">
        <div class="section-head text-center">
            <span>Services</span>
            <h2>Viscous Services</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore et dolore magna aliqua.</p>
        </div>
  
        <div class="service-slider owl-carousel owl-theme">
            <div class="service-item">
                <i class="flaticon-cleaning-spray"></i>
                <h3>Professional Cleaning</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc.</p>

                <div class="theme-button">
                    <a routerLink="/service-details" class="default-btn">Read More</a>
                </div>

                <div class="service-shape">
                    <img src="assets/img/service/shapes/service-pattern-6.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-7.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-8.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-9.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-10.png" alt="shape">
                </div>
            </div>

            <div class="service-item">
                <i class="flaticon-clean-house"></i>
                <h3>House Cleaning</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc.</p>

                <div class="theme-button">
                    <a routerLink="/service-details" class="default-btn">Read More</a>
                </div>

                <div class="service-shape">
                    <img src="assets/img/service/shapes/service-pattern-6.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-7.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-8.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-9.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-10.png" alt="shape">
                </div>
            </div>

            <div class="service-item">
                <i class="flaticon-wiper"></i>
                <h3>Office Cleaning</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc.</p>

                <div class="theme-button">
                    <a routerLink="/service-details" class="default-btn">Read More</a>
                </div>

                <div class="service-shape">
                    <img src="assets/img/service/shapes/service-pattern-6.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-7.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-8.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-9.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-10.png" alt="shape">
                </div>
            </div>

            <div class="service-item">
                <i class="flaticon-cleaning-spray"></i>
                <h3>Professional Cleaning</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc.</p>

                <div class="theme-button">
                    <a routerLink="/service-details" class="default-btn">Read More</a>
                </div>

                <div class="service-shape">
                    <img src="assets/img/service/shapes/service-pattern-6.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-7.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-8.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-9.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-10.png" alt="shape">
                </div>
            </div>

            <div class="service-item">
                <i class="flaticon-clean-house"></i>
                <h3>House Cleaning</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc.</p>

                <div class="theme-button">
                    <a routerLink="/service-details" class="default-btn">Read More</a>
                </div>

                <div class="service-shape">
                    <img src="assets/img/service/shapes/service-pattern-6.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-7.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-8.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-9.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-10.png" alt="shape">
                </div>
            </div>

            <div class="service-item">
                <i class="flaticon-wiper"></i>
                <h3>Office Cleaning</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc.</p>

                <div class="theme-button">
                    <a routerLink="/service-details" class="default-btn">Read More</a>
                </div>

                <div class="service-shape">
                    <img src="assets/img/service/shapes/service-pattern-6.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-7.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-8.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-9.png" alt="shape">
                    <img src="assets/img/service/shapes/service-pattern-10.png" alt="shape">
                </div>
            </div>                    
        </div>
    </div>
</div>

<section class="why-us">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 p-0">
                <div class="why-us-img"></div>
            </div>

            <div class="col-lg-6">
                <div class="why-us-text ptb-100">
                    <div class="section-head">
                        <span>Why Chooes Us</span>
                        <h2>People Trust on Us</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore et dolore magna aliqua sit amet, consectetur.</p>
                    </div>

                    <div class="media d-flex">
                        <i class="icofont-check align-self-start mr-3"></i>
                        <div class="media-body">
                          <h5 class="mt-0">Experts Cleaners</h5>
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore et dolore magna aliqua sit amet.</p>
                        </div>
                    </div>

                    <div class="media d-flex">
                        <i class="icofont-check align-self-start mr-3"></i>
                        <div class="media-body">
                          <h5 class="mt-0">Latest Equipment</h5>
                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore et dolore magna aliqua sit amet.</p>
                        </div>
                    </div>

                    <div class="media d-flex">
                        <i class="icofont-check align-self-start mr-3"></i>
                        <div class="media-body">
                          <h5 class="mt-0">Clean & Fast Service</h5>
                          <p>Lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore et dolore magna aliqua sit amet.</p>
                        </div>
                    </div>

                    <div class="media d-flex">
                        <i class="icofont-check align-self-start mr-3"></i>
                        <div class="media-body">
                          <h5 class="mt-0">Budget Oriented</h5>
                          <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore et dolore magna aliqua sit amet.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="process-section process-style-three pb-70">
    <div class="container">
        <div class="section-head text-center">
            <span>Working Process</span>
            <h2>How Do We Work</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-md-4 col-sm-6">
                <div class="process-card">
                    <i class="icofont-touch"></i>
                    <h3>Choose Services</h3>
                    <p>Lorem Ipsum is simply dummied text of the printing and typesetting industry.</p>
                    <img src="assets/img/process-pattern-2.png" alt="arrow">
                </div>
            </div> 
            
            <div class="col-md-4 col-sm-6">
                <div class="process-card">
                    <i class="icofont-world"></i>
                    <h3>Booking Online</h3>
                    <p>Lorem Ipsum is simply dummied text of the printing and typesetting industry.</p>
                    <img src="assets/img/process-pattern-2.png" alt="arrow">
                </div>
            </div> 

            <div class="col-md-4 col-sm-6 offset-sm-3 offset-md-0">
                <div class="process-card">
                    <i class="icofont-recycle-alt"></i>
                    <h3>Enjoy Cleaning</h3>
                    <p>Lorem Ipsum is simply dummied text of the printing and typesetting industry.</p>
                </div>
            </div> 
        </div>

        <div class="process-shapes">
            <img src="assets/img/process-pattern-3.png" alt="shape">
            <img src="assets/img/process-pattern-4.png" alt="shape">
            <img src="assets/img/process-pattern-5.png" alt="shape">
            <img src="assets/img/process-pattern-6.png" alt="shape">
            <img src="assets/img/process-pattern-7.png" alt="shape">
            <img src="assets/img/process-pattern-6.png" alt="shape">
        </div>
    </div>
</div>

<section class="recent-project-section pt-100 pb-70">
    <div class="container-fluid p-0">
        <div class="section-head text-center">
            <span>Our Works</span>
            <h2>Our Recent Works</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore et dolore magna aliqua.</p>
        </div>

        <div class="recent-project-slider owl-carousel owl-theme">
            <div class="recent-project-item">
                <div class="project-img">
                    <img src="assets/img/recent-work/6.png" alt="image">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/recent-work/6.png" class="popup-gallery">
                                    <i class="icofont-eye-alt"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="project-text">
                    <h3><a routerLink="/">Hotel Cleaning</a></h3>
                    <p>Apartment Cleaning</p>
                </div>
            </div>

            <div class="recent-project-item">
                <div class="project-img">
                    <img src="assets/img/recent-work/7.png" alt="image">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/recent-work/7.png" class="popup-gallery">
                                    <i class="icofont-eye-alt"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="project-text">
                    <h3><a routerLink="/">Glass Cleaning</a></h3>
                    <p>Apartment Cleaning</p>
                </div>
            </div>

            <div class="recent-project-item">
                <div class="project-img">
                    <img src="assets/img/recent-work/9.png" alt="image">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/recent-work/9.png" class="popup-gallery">
                                    <i class="icofont-eye-alt"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="project-text">
                    <h3><a routerLink="/">House Cleaning</a></h3>
                    <p>Apartment Cleaning</p>
                </div>
            </div>

            <div class="recent-project-item">
                <div class="project-img">
                    <img src="assets/img/recent-work/10.png" alt="image">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/recent-work/10.png" class="popup-gallery">
                                    <i class="icofont-eye-alt"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="project-text">
                    <h3><a routerLink="/">Housing Estate</a></h3>
                    <p>Apartment Cleaning</p>
                </div>
            </div>

            <div class="recent-project-item">
                <div class="project-img">
                    <img src="assets/img/recent-work/8.png" alt="image">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/recent-work/8.png" class="popup-gallery">
                                    <i class="icofont-eye-alt"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="project-text">
                    <h3><a routerLink="/">Resort Cleaning</a></h3>
                    <p>Apartment Cleaning</p>
                </div>
            </div>

            <div class="recent-project-item">
                <div class="project-img">
                    <img src="assets/img/recent-work/9.png" alt="image">

                    <div class="recent-hover">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <a href="assets/img/recent-work/9.png" class="popup-gallery">
                                    <i class="icofont-eye-alt"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="project-text">
                    <h3><a routerLink="/">House Cleaning</a></h3>
                    <p>Apartment Cleaning</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="pricing-section pb-70">
    <div class="container">
        <div class="section-head text-center">
            <h2>Our <span>Pricing Plan</span></h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="price-card-area">
                    <div class="price-head-text">
                        <h4>Basic Plan</h4>
                        <h2>$<span>60</span>/month</h2>
                    </div>

                    <div class="plan-features">
                        <ul>
                            <li><i class="icofont-check-circled"></i> Fully Satisfaction</li>
                            <li><i class="icofont-check-circled"></i> 5 Hours</li>
                            <li><i class="icofont-check-circled"></i> 1 time in a week</li>
                            <li><i class="icofont-check-circled"></i> Full Cleaning</li>
                            <li><i class="icofont-check-circled"></i> All Our Equipment</li>
                        </ul>
                    </div>

                    <div class="plan-btn">
                        <a routerLink="/">Booking Now</a>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="price-card-area active-price">
                    <div class="price-head-text">
                        <h4>Standard Plan</h4>
                        <h2>$<span>99</span>/month</h2>
                    </div>

                    <div class="plan-features">
                        <ul>
                            <li><i class="icofont-check-circled"></i> Fully Satisfaction</li>
                            <li><i class="icofont-check-circled"></i> 7 Hours</li>
                            <li><i class="icofont-check-circled"></i> 2 time in a week</li>
                            <li><i class="icofont-check-circled"></i> Full Cleaning</li>
                            <li><i class="icofont-check-circled"></i> All Our Equipment</li>
                        </ul>
                    </div>

                    <div class="plan-btn">
                        <a routerLink="/">Booking Now</a>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 offset-md-3 col-sm-6  offset-lg-0 offset-sm-3">
                <div class="price-card-area">
                    <div class="price-head-text">
                        <h4>Premium Plan</h4>
                        <h2>$<span>159</span>/month</h2>
                    </div>

                    <div class="plan-features">
                        <ul>
                            <li><i class="icofont-check-circled"></i> Fully Satisfaction</li>
                            <li><i class="icofont-check-circled"></i> 10 Hours</li>
                            <li><i class="icofont-check-circled"></i> 3 time in a week</li>
                            <li><i class="icofont-check-circled"></i> Full Cleaning</li>
                            <li><i class="icofont-check-circled"></i> All Our Equipment</li>
                        </ul>
                    </div>

                    <div class="plan-btn">
                        <a routerLink="/">Booking Now</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="team-section pb-70">
    <div class="container">
        <div class="section-head text-center">
            <h2>Our <span>Team Members</span></h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/1.png" alt="team member">

                        <div class="team-social">
                            <a href="#" target="_blank"><i class="icofont-facebook"></i></a>
                            <a href="#" target="_blank"><i class="icofont-twitter"></i></a>
                            <a href="#" target="_blank"><i class="icofont-linkedin"></i></a>
                            <a href="#" target="_blank"><i class="icofont-instagram"></i></a>
                        </div>
                    </div>

                    <div class="team-text">
                        <h4>John Smith</h4>
                        <p>Head of Cleaner</p>
                    </div>
                </div>
            </div> 
            
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/2.png" alt="team member">

                        <div class="team-social">
                            <a href="#" target="_blank"><i class="icofont-facebook"></i></a>
                            <a href="#" target="_blank"><i class="icofont-twitter"></i></a>
                            <a href="#" target="_blank"><i class="icofont-linkedin"></i></a>
                            <a href="#" target="_blank"><i class="icofont-instagram"></i></a>
                        </div>
                    </div>

                    <div class="team-text">
                        <h4>Marry</h4>
                        <p>Cleaner</p>
                    </div>
                </div>
            </div> 

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/3.png" alt="team member">

                        <div class="team-social">
                            <a href="#" target="_blank"><i class="icofont-facebook"></i></a>
                            <a href="#" target="_blank"><i class="icofont-twitter"></i></a>
                            <a href="#" target="_blank"><i class="icofont-linkedin"></i></a>
                            <a href="#" target="_blank"><i class="icofont-instagram"></i></a>
                        </div>
                    </div>

                    <div class="team-text">
                        <h4>John Smith</h4>
                        <p>Office Cleaner</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="team-card">
                    <div class="team-img">
                        <img src="assets/img/team/4.png" alt="team member">

                        <div class="team-social">
                            <a href="#" target="_blank"><i class="icofont-facebook"></i></a>
                            <a href="#" target="_blank"><i class="icofont-twitter"></i></a>
                            <a href="#" target="_blank"><i class="icofont-linkedin"></i></a>
                            <a href="#" target="_blank"><i class="icofont-instagram"></i></a>
                        </div>
                    </div>

                    <div class="team-text">
                        <h4>Eve</h4>
                        <p>Assistant</p>
                    </div>
                </div>
            </div> 
        </div>
    </div>
</section>

<div class="testimonial-section testimonial-bg ptb-100">
    <div class="testimonial-area">
        <div class="container">
            <div class="testimonial-slider owl-carousel owl-theme text-center">
                <div class="sin-testiText">
                    <h2>Stepheny Mc.man</h2>
                    <span>Co-worker of 3S</span>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do is ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices . Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                </div>

                <div class="sin-testiText">
                    <h2>Jackson</h2>
                    <span>Graphics Designer of 3S</span>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do is ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices . Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                </div>

                <div class="sin-testiText">
                    <h2>Amelia</h2>
                    <span>Web Developer of 3S</span>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do is ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices . Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="blog-section pt-100">
    <div class="container">
        <div class="section-head text-center">
            <h2>Latest <span>Blogs</span></h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore et dolore magna aliqua.</p>
        </div>

        <div class="blog-slider owl-carousel owl-theme">
            <div class="blog-area">
                <div class="blog-img">
                    <a routerLink="/blog-details" class="d-block"><img src="assets/img/blog/1.png" alt="blog image"></a>
                </div>

                <div class="blog-text">
                    <h4><a routerLink="/blog-details">How to cleanroom yourself</a></h4>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
                        <li><i class="icofont-ui-user"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a routerLink="/blog-details" class="blog-btn">Read More <i class="icofont-rounded-right"></i></a>
                </div>
            </div>

            <div class="blog-area">
                <div class="blog-img">
                    <a routerLink="/blog-details" class="d-block"><img src="assets/img/blog/2.png" alt="blog image"></a>
                </div>

                <div class="blog-text">
                    <h4><a routerLink="/blog-details">Enjoy the working time</a></h4>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
                        <li><i class="icofont-ui-user"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a routerLink="/blog-details" class="blog-btn">Read More <i class="icofont-rounded-right"></i></a>
                </div>
            </div>

            <div class="blog-area">
                <div class="blog-img">
                    <a routerLink="/blog-details" class="d-block"><img src="assets/img/blog/3.png" alt="blog image"></a>
                </div>

                <div class="blog-text">
                    <h4><a routerLink="/blog-details">Self cleaning is the best drill</a></h4>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
                        <li><i class="icofont-ui-user"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a routerLink="/blog-details" class="blog-btn">Read More <i class="icofont-rounded-right"></i></a>
                </div>
            </div>

            <div class="blog-area">
                <div class="blog-img">
                    <a routerLink="/blog-details" class="d-block"><img src="assets/img/blog/1.png" alt="blog image"></a>
                </div>

                <div class="blog-text">
                    <h4><a routerLink="/blog-details">How to cleanroom yourself</a></h4>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
                        <li><i class="icofont-ui-user"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a routerLink="/blog-details" class="blog-btn">Read More <i class="icofont-rounded-right"></i></a>
                </div>
            </div>

            <div class="blog-area">
                <div class="blog-img">
                    <a routerLink="/blog-details" class="d-block"><img src="assets/img/blog/2.png" alt="blog image"></a>
                </div>

                <div class="blog-text">
                    <h4><a routerLink="/blog-details">Enjoy the working time</a></h4>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
                        <li><i class="icofont-ui-user"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a routerLink="/blog-details" class="blog-btn">Read More <i class="icofont-rounded-right"></i></a>
                </div>
            </div>

            <div class="blog-area">
                <div class="blog-img">
                    <a routerLink="/blog-details" class="d-block"><img src="assets/img/blog/3.png" alt="blog image"></a>
                </div>

                <div class="blog-text">
                    <h4><a routerLink="/blog-details">Self cleaning is the best drill</a></h4>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
                        <li><i class="icofont-ui-user"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a routerLink="/blog-details" class="blog-btn">Read More <i class="icofont-rounded-right"></i></a>
                </div>
            </div>

            <div class="blog-area">
                <div class="blog-img">
                    <a routerLink="/blog-details" class="d-block"><img src="assets/img/blog/1.png" alt="blog image"></a>
                </div>

                <div class="blog-text">
                    <h4><a routerLink="/blog-details">How to cleanroom yourself</a></h4>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
                        <li><i class="icofont-ui-user"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a routerLink="/blog-details" class="blog-btn">Read More <i class="icofont-rounded-right"></i></a>
                </div>
            </div>

            <div class="blog-area">
                <div class="blog-img">
                    <a routerLink="/blog-details" class="d-block"><img src="assets/img/blog/2.png" alt="blog image"></a>
                </div>

                <div class="blog-text">
                    <h4><a routerLink="/blog-details">Enjoy the working time</a></h4>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
                        <li><i class="icofont-ui-user"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a routerLink="/blog-details" class="blog-btn">Read More <i class="icofont-rounded-right"></i></a>
                </div>
            </div>

            <div class="blog-area">
                <div class="blog-img">
                    <a routerLink="/blog-details" class="d-block"><img src="assets/img/blog/3.png" alt="blog image"></a>
                </div>

                <div class="blog-text">
                    <h4><a routerLink="/blog-details">Self cleaning is the best drill</a></h4>
                    <ul>
                        <li><i class="icofont-calendar"></i> 20 Feb 2020</li>
                        <li><i class="icofont-ui-user"></i> <a routerLink="/">John Doe</a></li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor contratc ut labore.</p>
                    <a routerLink="/blog-details" class="blog-btn">Read More <i class="icofont-rounded-right"></i></a>
                </div>
            </div>
        </div>                 
    </div>
</section>